<template>
  <v-dialog
    v-model="innerValue"
    transition="slide-y-transition"
    class="elevation-0"
    persistent
    scrollable
    max-width="450px"
  >
    <v-card>
      <v-card-title class="grey lighten-4">
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $t("sso_forgot_password_warning.title") }}
          </v-list-item-title>
        </v-list-item-content>
        <v-spacer />
        <v-btn color="grey lighten-1" icon @click="handleCancelClick">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5 body-1">
        {{ $t("sso_forgot_password_warning.message") }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "SSOWarningDialog",
    props: { value: Boolean },
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      }
    },
    methods: {
      handleCancelClick() {
        this.innerValue = false;
        this.$router.push({ name: "login" });
      }
    }
  };
</script>
